import { EventItemTypes } from '@index/enums/event-item-type';
import {
  EventItem,
  EventJoiner,
  EventJoinerStatus,
  EventRsvpStatus, EventSlotGroup, EventTheme, EventTicketLevel,
  UnregisteredTeamInfo,
} from '@index/interfaces';

import { StripeItemType } from '../enums/misc';
import { StripeItem } from '../interfaces/stripe';
import { GthModel } from './model';
import { GthUserModel } from './user';

export enum JoinButtonType {
  JoinGame,
  JoinWaitlist,
  RequestJoinGame,
  AcceptInvite,
  Creator,
}

export class GthEventItemModel extends GthModel<EventItem> {
  constructor(id: string, model: EventItem) {
    if (typeof (model?.dateStart) === 'string') model.dateStart = new Date(model.dateStart);

    if (model?.dateStart && (model as any)?.dateStart.seconds) {
      const seconds = (model?.dateStart as any).seconds;
      model.dateStart = new Date(seconds * 1000);
    }
    super(id, model);
  }

  get isBasic() {
    return this.model.isBasic ?? false;
  }

  get isPrivate() {
    return this.model.isPrivate ?? false;
  }

  get allowParticipantGuests() {
    return this.model.allowParticipantGuests;
  }

  get banner() {
    return this.model.banner;
  }

  get title() {
    return this.model?.title ?? '';
  }

  get creator() {
    return this._creator;
  }

  get creatorId() {
    if (!this.model.creator) {
      return '';
    }

    return this.model.creator as string;
  }

  get description() {
    return this.model.description ?? '';
  }

  get favoritedList() {
    return this.model.favoritedList;
  }

  get dateStart() {
    return this.model.dateStart;
  }

  get hasTeamsForEventsOnly() {
    return !!this.model.eventTeamsOnly;
  }

  get teamsForEventsOnly() {
    return this.model.eventTeamsOnly;
  }

  getEventSlotsFromGroup(idx = 0) {
    return this.model?.eventSlotGroup?.[idx]?.slots ?? [];
  }

  hasEventSlots(index = 0): boolean {
    return !!this.model?.eventSlotGroup?.[index]?.slots?.length;
  }

  get getEventGroups() {
    const eventSlots = this.getEventSlotsFromGroup();
    const allGroups = eventSlots.flatMap((es) => es.groupsList || []);
    /** Create a set to remove duplicates */
    return [...new Set(allGroups)];
  }

  get hasGroups() {
    return !!this.getEventGroups.length;
  }

  get dateEnd() {
    const durationHours = this.duration.hours;
    const durationMinutes = this.duration.minutes;
    const endOfGame = new Date(this.dateStart);
    const newHours = endOfGame.getHours() + durationHours;
    const newMinutes = endOfGame.getMinutes() + durationMinutes;
    endOfGame.setHours(newHours, newMinutes);
    return endOfGame;
  }

  get duration() {
    return this.model.duration;
  }

  get location() {
    return this.model.location;
  }

  get online() {
    return this.model.online;
  }

  set minimumNeeded(val: number) {
    this.model.minimumNeeded = val;
  }

  get minimumNeeded() {
    return this.model.minimumNeeded;
  }

  get hoursToCancelBefore() {
    return this.model.hoursToCancelBefore;
  }

  set hoursToCancelBefore(val: number) {
    this.model.hoursToCancelBefore = val;
  }

  get eventJoinerStatus() {
    return this._eventJoinerStatus;
  }

  get skillLevel() {
    return this.model.skillLevel;
  }

  get eventType() {
    return this.model.type || EventItemTypes.Pickup;
  }

  get gameType() {
    const unregisteredTeam = this.model.hostingTeam as unknown as UnregisteredTeamInfo;
    if (unregisteredTeam && unregisteredTeam.gameType) {
      return unregisteredTeam.gameType;
    }
    return this.model.gameType ?? '';
  }

  /** Get Angular Material Icon for {@link GAME_TYPES} */
  // TODO: Some icons probably could be updated.
  get gameTypeIcon() {
    const defaultIcon = 'sports';
    switch (this.gameType) {
      /** Sports */
      case 'Football': return 'sports_football';
      case 'Soccer': return 'sports_soccer';
      case 'Volleyball': return 'sports_volleyball';
      case 'Basketball': return 'sports_basketball';
      case 'Baseball': return 'sports_baseball';
      case 'Lacrosse': return defaultIcon;
      case 'Softball': return defaultIcon;
      case 'Pickleball': return defaultIcon;
      /** Leisure */
      case 'Hiking': return 'hiking';
      case 'Camping': return 'nature';
      case 'Cooking': return 'local_fire_department';
      case 'Reading': return 'auto_stories';
      case 'Coding': return 'code';
      case 'Watch Party': return 'movie';
      case 'Board games': return 'casino';
      case 'Card games': return defaultIcon;
      case 'Table top': return defaultIcon;
      /** Exercise */
      case 'Running': return 'directions_run';
      case 'Weightlifting': return 'fitness_center';
      case 'Cycling': return 'directions_bike';
      case 'Backpacking': return 'backpack';
      /** Extreme */
      case 'Skateboard': return 'skateboarding';
      case 'Other':
      default: return defaultIcon;
    }
  }

  get teamName() {
    const teamName = this.model.teamName;
    if (teamName) return teamName;

    if (typeof this.hostingTeam === 'string') {
      return '';
    } else if (this.hostingTeam?.name) {
      return this.hostingTeam.name;
    }
    return this.model.teamName;
  }

  get hostingTeam() {
    return this.model.hostingTeam;
  }
  set hostingTeam(val: string | UnregisteredTeamInfo) {
    this.model.hostingTeam = val;
  }

  getPlayerCount(joiners: EventJoiner[]) {
    let participantsResponded = 0;
    let playersNeeded = 0;

    const uniqueParticipants = Array.from(new Set(
      joiners.map(
        (item) => item,
      ),
    )) || [];

    const esg = this.model.eventSlotGroup || [];
    esg.map((group) => {
      playersNeeded += group.slots.reduce((total, slot) => total + slot.capacity, 0);
    });

    for (const joiner of uniqueParticipants) {
      participantsResponded +=
        joiner.rsvpStatus === EventRsvpStatus.PLAYING ?
          1 : 0;
    }

    if (this.model.isBasic && playersNeeded === 0) {
      playersNeeded = 99999;
    }

    return {
      participantsResponded,
      playersNeeded: playersNeeded - participantsResponded,
      totalNeeded: playersNeeded,
    };
  }

  get cost() {
    const cost = this.model?.cost;
    if (
      !cost ||
      cost === null ||
      cost === 0 ||
      cost.toString().trim() === '0' ||
      cost.toString().trim() === '') {
      return undefined;
    }
    return this.model.cost;
  }

  get equipmentNeeded() {
    return this.model.equipmentNeeded;
  }

  get makePublicAfter() {
    return this.model.makePublicAfter;
  }

  get creatorApprovalNeeded() {
    return this.model.creatorApprovalNeeded ?? false;
  }

  set creatorApprovalNeeded(val: boolean) {
    this.model.creatorApprovalNeeded = val ?? false;
  }

  get platform() {
    return this.model.platform ?? 'gth';
  }
  set platform(val: string) {
    this.model.platform = val;
  }

  get id() {
    return this._id || this.model.id;
  }

  get participantInfo() {
    return this.model.joiners;
  }

  set participantInfo(joiners: EventJoiner[]) {
    this.model.joiners = joiners;
  }

  get participants(): EventJoiner[] {
    return this.model.joiners;
  }
  set participants(val: EventJoiner[]) {
    this.model.joiners = val;
  }

  get approvedPlayerIds(): string[] {
    const players: string[] = [];

    this.participants.forEach((p) => {
      if (p.status === EventJoinerStatus.Approved) {
        players.push(p.player);
      }
    });

    return players;
  }

  get waitList() {
    const players: string[] = [];

    this.participants.forEach((p) => {
      if (p.status === EventJoinerStatus.Waitlisted) {
        players.push(p.player);
      }
    });

    return players;
  }

  get cancelled() {
    return this.model.cancelled;
  }

  cancel() {
    this.model.cancelled = true;
  }

  get survey() {
    return this.model.survey;
  }

  get inPast() {
    const today = new Date();
    return this.dateStart.getTime() < today.getTime();
  }

  get eventSlotGroup() {
    return this.model.eventSlotGroup;
  }

  set genderInfo(group: EventSlotGroup[]) {
    this.model.eventSlotGroup = group;
  }

  get rated() {
    return this.model.rated;
  }
  set rated(val: boolean) {
    this.model.rated = val;
  }

  get discoverable() {
    return this.model.discoverable ?? false;
  }
  set discoverable(val: boolean) {
    this.model.discoverable = val;
  }

  get theme() {
    return this.model.theme ?? EventTheme.Sports;
  }
  set theme(val: EventTheme) {
    this.model.theme = val;
  }

  get backgroundColor() {
    return this.model.backgroundColor ?? '';
  }
  set backgroundColor(val: string) {
    this.model.backgroundColor = val;
  }

  get sendFeedbackEmailAfter() {
    return this.model.sendFeedbackEmailAfter ?? false;
  }
  set sendFeedbackEmailAfter(val: boolean) {
    this.model.sendFeedbackEmailAfter = val;
  }

  get ticketLevels() {
    return this.model.ticketLevels ?? [];
  }
  set ticketLevels(val: EventTicketLevel[]) {
    this.model.ticketLevels = val;
  }

  get priceId() {
    return this.model.priceId ?? '';
  }
  set priceId(val: string) {
    this.model.priceId = val;
  }

  get allowMaybeRsvp() {
    return this.model.allowMaybeRsvp ?? false;
  }
  set allowMaybeRsvp(val: boolean) {
    this.model.allowMaybeRsvp = val;
  }

  get requireGuestInformation() {
    return this.model?.requireGuestInformation ?? false;
  }
  set requireGuestInformation(val: boolean) {
    this.model.requireGuestInformation = val;
  }

  get allowUnregistered() {
    return this.model.allowUnregistered ?? false;
  }
  set allowUnregistered(val: boolean) {
    this.model.allowUnregistered = val;
  }

  private _creator?: GthUserModel;
  private _eventJoinerStatus?: EventJoinerStatus;
  private _selectedTicketLevel?: EventTicketLevel;

  setSelectedTicketLevel(val: EventTicketLevel) {
    this._selectedTicketLevel = val;
  }
  get selectedTicketLevel() {
    return this._selectedTicketLevel ?? null;
  }

  setCreator(val: GthUserModel) {
    this._creator = val;
    this.model.creator = val?.uid;
  }

  setEventType(eventType: EventItemTypes) {
    this.model.type = eventType;
  }

  getTeamPlayers(user: GthUserModel | undefined): GthUserModel[] {
    if (!user) {
      return [];
    }

    return [];
  }

  toItem(): StripeItem {
    return {
      id: this.id,
      name: this.title,
      quantity: 1,
      cost: this.cost ?? 0,
      type: StripeItemType.JOIN_EVENT,
      platform: this.platform as 'gth' | 'meh',
    };
  }
}
